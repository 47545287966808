<template>
    <v-container fluid class="p-4" style="padding-bottom:150px">
        <v-row>
            <v-col cols="12">
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>

                    <v-spacer></v-spacer>

                    <v-text-field
                        solo
                        clearable
                        class="border-12"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        @keyup.enter="getPullData()"
                        hide-details
                        style="max-width:400px"
                    ></v-text-field>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-5" style="padding-bottom:150px">
            <v-col cols="12">
                <v-card class="rounded-l elevation-5">
                    <v-card-title>
                        PPP Approval
                        <v-spacer></v-spacer>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-2"
                        @click="getPullData()"
                        >
                            <v-icon>mdi-cached</v-icon>
                        </v-btn>
                        <v-btn
                        small
                        color="#fff"
                        class="py-5 mr-2"
                        @click="dialog = true"
                        >
                            <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                        
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="ppps"
                            item-key="CustomerOrderNo"
                            class="elevation-1"
                        >
                        <template v-slot:[`item.wgt`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                        </template>
                        <template v-slot:[`item.stat`]="{ item }">
                            {{item.stat}}
                        </template>                       
                        <template v-slot:[`item.action`]="{ item }">
                            <v-menu
                                bottom
                                left
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense>
                                    <v-list-item @click="getDetail(item)">
                                        <v-list-item-icon>
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Detail</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && aprv_access" @click="setApprove(item, 'approve')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-check-circle-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>Approve</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000' && aprv_access" @click="setApprove(item, 'UnApprove')">
                                        <v-list-item-icon>
                                            <v-icon>mdi-cached</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>UnApprove</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="600px"
                >
                    <v-card class="p-2 rounded-l">
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    Filter Approval PPP
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row class="mt-2 mb-5">
                                    <v-col class="col-12" sm="6" md="4">
                                        <p class="blue-lcd mb-1">Approval</p>   
                                        <v-autocomplete
                                            solo
                                            dense
                                            clearable
                                            v-model="approve"
                                            :items="approves"
                                            item-value="value"
                                            item-text="text"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                        ></v-autocomplete> 
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="4">
                                        <p class="blue-lcd mb-1">Start Date</p>   
                                        <v-menu
                                            ref="modal"
                                            v-model="modal"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                clearable
                                                solo
                                                dense
                                                v-model="date_from"
                                                persistent-hintnt
                                                append-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="date_from"
                                                no-title
                                                @input="modal = false"
                                            ></v-date-picker>
                                        </v-menu>
                                        
                                    </v-col>
                                    <v-col class="col-12" sm="6" md="4">
                                        <p class="blue-lcd mb-1">End Date</p> 
                                        <v-menu
                                            ref="modal_to"
                                            v-model="modal_to"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                clearable
                                                solo
                                                dense
                                                v-model="date_to"
                                                persistent-hintnt
                                                append-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                class="ma-0 pa-0 border-12"
                                                hide-details=true
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="date_to"
                                                no-title
                                                @input="modal_to = false"
                                            ></v-date-picker>
                                        </v-menu> 
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="blue-lcd mb-1">Search</p> 
                                        <v-text-field
                                            solo
                                            clearable
                                            class="border-12"
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            @keyup.enter="getPullData()"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="end">
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l mr-2" color="#fff" @click="dialog = false">Cancel</v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l text-white" :loading="loading2" :disabled="loading2" color="primary" @click="filter()">
                                        <template v-slot:loader>
                                            <span>Loading...</span>
                                        </template>
                                        OK
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogItem"
                    persistent
                    max-width="1000px"
                >
                    <v-card class="p-2 rounded-l">
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    Detail PPP
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row class="mt-2 mb-5">
                                    <v-col cols="12">
                                        <v-data-table
                                            :headers="header_items"
                                            :items="ppp_items"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.wgt`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                                            </template>
                                            <template v-slot:[`item.wgt_ord`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.wgt_ord) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="end">
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l mr-2" color="#fff" @click="dialogItem = false">Close</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogOrder"
                    persistent
                    max-width="1000px"
                >
                    <v-card class="p-2 rounded-l">
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    Detail Order
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row class="mt-2 mb-5">
                                    <v-col class="col-12 pt-0 pb-1" sm="6" md="4">
                                        <h6 class="blue-lcd mb-1">Sales</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="salesman_name"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                    <v-col class="col-12 pt-0 pb-1" sm="6" md="4">
                                        <h6 class="blue-lcd mb-1">Customer</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="cust_name"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                    <v-col class="col-12 pt-0 pb-1" sm="6" md="4">
                                        <h6 class="blue-lcd mb-1">Customer Group</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="cust_grp_name"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                    <v-col class="col-12 pt-0 pb-1" sm="6" md="4">
                                        <h6 class="blue-lcd mb-1">Pay Term ID</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="pay_term_id"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                    <v-col class="col-12 pt-0 pb-1" sm="12" md="8">
                                        <h6 class="blue-lcd mb-1">Pay Term Desc</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="pay_term_desc"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                    
                                </v-row>
                                <v-row class="mt-1 pt-0">
                                    <v-col class="col-12 pt-0 pb-1" sm="6" md="3">
                                        <h6 class="blue-lcd mb-1">Credit Limit</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="amt_credit_limit"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                    <v-col class="col-12 pt-0 pb-1" sm="6" md="3">
                                        <h6 class="blue-lcd mb-1">Piutang</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="piutang_credit_limit"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                    <v-col class="col-12 pt-0 pb-1" sm="6" md="3">
                                        <h6 class="blue-lcd mb-1">Deliv Amount</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="deliv_amount"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                    <v-col class="col-12 pt-0 pb-1" sm="6" md="3">
                                        <h6 class="blue-lcd mb-1">Credit Limit Balance</h6>
                                        <v-text-field
                                            dense
                                            solo
                                            persistent-hint
                                            v-model="credit_limit_balance"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            readonly
                                        >
                                        </v-text-field> 
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="end" class="mt-5">
                                <v-col cols="12" xs="12" sm="3" md="3">
                                    <v-btn block class="rounded-l mr-2" color="#fff" @click="dialogOrder = false">Close</v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="3" v-if="aprv === 'approve'">
                                    <v-btn block class="rounded-l text-white" color="#0078d4" type="button" @click="Approve()">Approve</v-btn>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="3" v-else>
                                    <v-btn block class="rounded-l text-white" color="red" type="button" @click="UnApprove()">UnApprove</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Approval',
                disabled: true,
                },
                {
                text: 'PPP',
                disabled: true,
                href: '',
                }
            ],
            loading: false,
            display: 'none',
            options: {},
            search: '',
            singleSelect: false,
            selected: [],
            headers: [
                {
                    text: 'LPM ID',
                    align: 'start',
                    value: 'lpm_id', 
                    align:'center'},
                { text: 'Order ID', value: 'order_id' , align:'center'},
                { text: 'Date Trans', value: 'dt_trx' , align:'center'},
                { text: 'Wgt', value: 'wgt' , align:'center'},
                { text: 'Status', value: 'stat' , align:'center'},
                { text: '', value: 'action' , align:'center'},
            ],
            ppps: [],
            header_items:[
                {
                    text: 'LPM ID',
                    align: 'start',
                    value: 'lpm_id', 
                    align:'center'},
                { text: 'Item', value: 'item_num' , align:'center'},
                { text: 'ProdCode', value: 'prod_code' , align:'center'},
                { text: 'Wgt', value: 'wgt' , align:'center'},
                { text: 'Wgt Ord', value: 'wgt_ord' , align:'center'},
                { text: 'Order ID', value: 'order_id' , align:'center'}
            ],
            ppp_items: [],
            selectOrders: [],
            display_alert: false,
            dialog: false,
            regions: [],
            region: '',
            offices: [],
            office: '',
            divisions: [],
            division: '',
            approves: [
                {
                    value : "not_approved",
                    text : "Belum di Approved"
                },
                {
                    value : "approved",
                    text : "Sudah di Approved"
                }
            ],
            approve: '',
            mills: [],
            mill: '',
            saleses: [],
            sales: '',
            customers: [],
            customer: '',
            order_ids: [],
            order_id: '',
            payterms: [
                {
                    pay_term_id: 'all',
                    pay_term_desc: 'ALL'
                },
                {
                    pay_term_id: 'cash',
                    pay_term_desc: 'CASH'
                },
                {
                    pay_term_id: 'tt',
                    pay_term_desc: 'TT'
                }
            ],
            payterm: '',
            statuses: [],
            status: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            searchCustomer: null,
            searchOrder: null,
            detail: '',
            dialogItem: false,
            order: '',
            aprv: '',
            salesman_name: '',
            pay_term_id: '',
            pay_term_desc: '',
            cust_name: '',
            cust_grp_name: '',
            amt_credit_limit: '',
            dialogOrder: false,
            aprv_access: '',
            piutang_credit_limit: '',
            deliv_amount: '',
            credit_limit_balance: '',
            loading2: false,
            loader: null,
        }
    },
    mounted(){
        this.envConf()
        console.log(this.$store.state.sm.group_id);
    },
    methods:{ 
        filter() {
            this.loading2 = true
            this.loader = 'loading'
            this.getPullData()

            this.date_from = ''
            this.date_to = ''
            this.search = ''
            this.approve = ''
            this.dialog = false
        },
        async envConf(){
            const respVar = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=SM&appl_id=WEBSM&var_id=APRV&groupid=${this.$store.state.sm.group_id}`, null, false, false, false)

            if (respVar.data.data != null) {
                if (respVar.data.data === 'Y') {
                    this.aprv_access = respVar.data.data
                }
            }

            this.getPullData()

        },  
        async getPullData(){
            this.$store.dispatch('setOverlay', true)

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''
            
            const respData = await backendApi.fetchCore(`/v2/sm/ppp?txtApproved=${this.approve ? this.approve : 'not_approved'}&start_date=${start_date}&end_date=${end_date ? end_date : ""}&search=${this.search ? this.search : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                this.loading2 = false
                this.ppps = respData.data
            } else {
                this.$store.dispatch('setOverlay', false)
                this.loading2 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }   

        },
        async getDetail(item){
            this.detail = item
            this.dialogItem = true
            
            this.ppp_items = []

            this.$store.dispatch('setOverlay', true)
            
            const respData = await backendApi.fetchCore(`/v2/sm/ppp/show?lpm_id=${item ? item.lpm_id : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)

                this.ppp_items = respData.data
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }   
        },
        async setApprove(item, approve){
            this.detail = item
            this.aprv = approve
            this.$store.dispatch('setOverlay', true)
            
            const respData = await backendApi.fetchCore(`/v2/sm/ppp/showTransaction?order_id=${item ? item.order_id : ''}&lpm_id=${item ? item.lpm_id : ''}`, null, false, false, false)

            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)

                this.order = respData.data

                this.salesman_name = this.order ? this.order.salesman_name : ''
                this.pay_term_id = this.order ? this.order.pay_term_id : ''
                this.pay_term_desc = this.order ? this.order.pay_term_desc : ''
                this.cust_name = this.order ? this.order.cust_name : ''
                this.cust_grp_name = this.order ? this.order.cust_grp_name : ''
                this.amt_credit_limit = this.order ? this.$store.getters.convertToCurrencyUs(this.order.amt_credit_limit) : ''
                this.piutang_credit_limit = this.order ? this.$store.getters.convertToCurrencyUs(this.order.piutang) : ''
                this.deliv_amount = this.order ? this.$store.getters.convertToCurrencyUs(this.order.deliv_amount) : ''
                this.credit_limit_balance = this.order ? this.$store.getters.convertToCurrencyUs(this.order.credit_limit_balance) : ''

                this.dialogOrder = true
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }   
        },
        async Approve(){
            this.dialogOrder = false

            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'lpm_id': this.detail.lpm_id.trim()
            }

            const respData = await backendApi.fetchCore('/v2/sm/ppp/approve', reqBody, false, false, false)
            
            if (respData.status === 200) {
                this.dialogOrder = false

                this.$store.dispatch('setOverlay', false)

                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve PPP",
                    visible: true
                };
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }
        },
        async UnApprove(){
            this.dialogOrder = false

            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'lpm_id': this.detail.lpm_id.trim()
            }

            const respData = await backendApi.fetchCore('/v2/sm/ppp/unApprove', reqBody, false, false, false)
            
            if (respData.status === 200) {
                this.dialogOrder = false
                this.$store.dispatch('setOverlay', false)

                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully unApprove LPM",
                    visible: true
                };

            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Internal Server Error' + respData.status,
                    visible: true
                };
            }
        }
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display == 'block') {
                    // this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        },
        searchCustomer (val) {
            val && val !== this.customer && this.getCustomer(val)
        },
        searchOrder (val) {
            val && val !== this.order_id && this.getOrder(val)
        },
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.loader = null
        },
    }
}
</script>